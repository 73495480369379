// core
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useCookies } from 'react-cookie'

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

// components
import NewsLetterSignUp from './NewsLetterSignUp'

//constants
import {SUBSCRIPTION_FORM} from "../constants/subscriptionFormLocation";
import { setSubscriptionPopUpShowEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'

const NewsLetterSignUpPopUp = () => {
  // country select functions
  const [showNewsLetterPopUp, setShowNewsLetterPopUp] = useState(false)
  // const closeNewsLetterPopUp = () => setShowNewsLetterPopUp(false)
  const [cookies, setCookie] = useCookies(['wc_subscriber'])
  // const [handleScroll, setHandleScroll] = useState(() => () => { })
    const closeNewsLetterPopUp = () => {
      if (cookies['wc_subscriber'] === "false") {
        let today = new Date();
        today.setDate(today.getDate() + 7);  // Set expiry date to a week from now
        let aweekFromCurrentDate = today;
        setCookie('wc_subscriber_exit', true, { expires: aweekFromCurrentDate, path: '/' });
      }
      setShowNewsLetterPopUp(false);
    };
    
  // Newsletter Signup Set Values
  const subscriptionFormTitle = "Join White Claw Shore Club"
  const subscriptionFormCopy = "Become a member of our crew and start Grabbing Life By The Claw™. Who’s in?"
  const subscriptionFormName = SUBSCRIPTION_FORM.POP_UP
  const consentAccepted = useConsent()
  const OGSourceId = 8
  const isBrowser = typeof window !== "undefined"

  const handleScroll = () => {
    if (cookies['wc_subscriber'] === "true" || (cookies['wc_subscriber_exit'] === "true")) {
      return; // Early exit if 'wc-subscriber' cookie exists
    }
    if (cookies?.adult && isBrowser && window.scrollY >= 500 ) {
        setShowNewsLetterPopUp(true)
        window.removeEventListener("scroll", handleScroll)
    }
  }

  useEffect(() => {
      window.addEventListener("scroll", handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get Date
  let today = new Date()
  today.setDate(today.getDate() + 365)
  let ayearFromCurrentDate = today
  useEffect(() => {
    if (cookies?.adult && isBrowser) {
      const urlParams = new URLSearchParams(window.location.search);
      const lidExists = urlParams.has('lid');
      const masubExists = urlParams.has('masub');
      if (lidExists || masubExists) {
        setCookie('wc_subscriber', true, { expires: ayearFromCurrentDate, path: '/' });
        setShowNewsLetterPopUp(false)
      }
    }
  }, [isBrowser, setCookie,ayearFromCurrentDate]);
  

  useEffect(() => {

    if(!showNewsLetterPopUp){
      return
    }

    setSubscriptionPopUpShowEvent(consentAccepted)

  }, [showNewsLetterPopUp])

  return (
    <>
      <Modal show={showNewsLetterPopUp} onHide={closeNewsLetterPopUp} animation={false} className="modal__newsletter-form">
        <Modal.Header className="position-relative">
          <button className="close-btn" data-dismiss="modal" aria-label="Close" onClick={closeNewsLetterPopUp} onKeyDown={closeNewsLetterPopUp}>
            <FontAwesomeIcon icon={faXmark} className="fa-solid"/>
          </button>
        </Modal.Header>
        <Modal.Body data-country-language-id="2" data-country="us">
          {/* Newsletter Signup */}
          <NewsLetterSignUp
          showNewsLetterPopUp={showNewsLetterPopUp}
          subscriptionFormTitle={subscriptionFormTitle}
          subscriptionFormCopy={subscriptionFormCopy}
          subscriptionFormName={subscriptionFormName}
          OGSourceId={OGSourceId}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default NewsLetterSignUpPopUp