// core
import React , { useEffect } from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import useWindowSize from "../hooks/useWindowSize"

// components
import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import contestHero from '../assets/images/contest/contest-sweep-image.png'
import contestHeroMobile from '../assets/images/contest/contest-sweep-image-mobile.png'


const WyngPage = ({ data }) => {
  const pageContent = data.prismicWyngPage
  const {meta_title, meta_description, social_card, wyng_id, wyng_token, show_header, show_footer} = data.prismicWyngPage.data
  const { lang, type, url, uid } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const isBrowser = typeof window !== "undefined";
  const wyngId = wyng_id ||''
  const wyngToken = wyng_token|| ''
  const wyngScript = "//dnsl4xr6unrmf.cloudfront.net/js/campaign.js"
  const showHeader = show_header
  const showFooter = show_footer
  const { width } = useWindowSize()
 // let isHolidayContest = false; // added for contest/holiday page only
 
  
  useEffect(() => {
    // append survey script
    const script = document.createElement('script')
    script.src = wyngScript
    script.async = true
    document.body.appendChild(script)
    // if(isBrowser){
    //   isHolidayContest = (window.location.pathname === '/contest/holiday');
    // }

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script)
    }

  }, [])


  return (
      <Layout activeDocMeta={activeDoc}
              showHeader={showHeader}
              showFooter={showFooter}
              noSignUpPopup={true}
      >
        <Seo
            title={meta_title?.text}
            description={meta_description?.text}
            social_card={social_card?.url}
            activeDocMeta={activeDoc}
        />
        
        { uid === 'holiday' &&
          <div className='wyng-header'>
            {width < 768 ? <img src={contestHeroMobile} alt="Holiday contest banner"/> :
            <img src={contestHero} alt="Holiday contest banner"/>}
          </div> 
        }
        <div className={uid === 'holiday' ? "wyng-experience wyng-page wyng-page-contest": "wyng-experience wyng-page"}
             data-wyng-id={wyngId}
             data-wyng-token={wyngToken}
        >
        </div>
      </Layout>
  )
}

export const query = graphql`
query WyngPageQuery ($uid: String, $id: String, $lang: String) {
  prismicWyngPage (uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
    _previewable
    alternate_languages {
      id
      lang
      type
      uid
    }
    data {
      meta_description {
        richText
        text
        html
      }
      meta_title {
        html
        richText
        text
      }
      show_header
      show_footer
      wyng_id
      wyng_token
      social_card {
        url
        alt
      }
    }
    uid
    type
    lang
    id
    url
  }
} `


export default withPrismicPreview(WyngPage)